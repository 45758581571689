body {
  font-family: "Poppins", sans-serif;
}

.float-left {
  float: left;
}
.ml-1 {
  margin-left: 1rem;
}
.w-250 {
  max-width: 250px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 23px;
  margin-right: 8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.rdt_Table {
  border: 1px solid #ddd;
  margin-top: 25px;
}
.rdt_TableHeadRow {
  background-color: #eee !important;
}

.permissions label {
  margin-bottom: 0;
}
.permissions label:not(:first-child) {
  margin-left: 9px;
}

.noImg {
  top: 60px;
  position: relative;
  font-size: 29px;
  width: 60px;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.editBtn {
  color: #3eb7ba;
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.search-expand {
  transition: 100ms linear all;
  width: 120px;
}
.search-expand:active,
.search-expand:focus {
  width: 300px;
  outline: 1px solid rgb(41, 1, 41);
}

.pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
