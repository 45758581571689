/* Loader.css */
.loader {
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .dots::after {
    content: '';
    display: inline-block;
    animation: dots 1s steps(3, end) infinite;
  }
  
  @keyframes dots {
    0%, 20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%, 100% {
      content: '...';
    }
  }
  